<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto max-w-sm">
      <transition
        name="fade"
        mode="out-in">
        <div
          class="my-12"
          v-if="view === 'initial'"
          key="initial">
          <form>
            <error-message v-if="error">
              {{ error }}
            </error-message>
            <input-field
              type="text"
              inputmode="numeric"
              v-model="referenceCode"
              label="Referansekode"
              minlength="11"
              maxlength="11"
              class="text-center text-lg"
              autofocus
              centered
            />

            <default-button
              :loading="submitting"
              @click.prevent.native="submit"
              primary
              class="w-full mt-2"
            >
              Verifiser
            </default-button>
          </form>
        </div>
        <div
          class="my-12"
          v-else-if="view === 'complete'"
          key="complete">
          <success-message> Referanse verifisert ok. </success-message>
          <div v-if="!this.personalia">
            Vi anbefaler at legitimasjon sjekkes på pasient.
          </div>

          <div
            v-else
            class="text-center">
            <div class="text-center">
              <span class="p-4 rounded-sm text-center text-2xl mt-4">Referansen gjelder pasient:</span>
            </div>
            <div class="mt-10">
              <span class="font-semibold p-4 rounded-sm text-center text-l mt-4">
                Fnr: 
              </span>
              {{ this.personalia.nin.substring(0,6) + " " + this.personalia.nin.substring(6,11) }}
            </div>
            <div>
              <span class="font-semibold p-4 rounded-sm text-center text-l mt-4">
                Navn: 
              </span>
              {{ this.personalia.name }}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"
import axios from "axios"

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },

    formData: {
      type: Object,
      default() {
        return {}
      }
    },

    setFormData: {
      type: Function,
      default() {
        return () => { }
      }
    }
  },

  data() {
    return {
      view: "initial",
      referenceCode: null,
      referenceId: null,
      submitting: false,
      token: null,
      error: null,
      retryEnabled: false,
      retryIntervalId: null,
      retryTimerId: null,
      maxRetryTimerSeconds: 20,
      timer: 20,
      personalia: null
    }
  },

  computed: {
    modelErrors() {
      return validate.single(this.formData.phoneVerificationId, {
        presence: true,
        type: "string"
      })
    },

    isValid() {
      return this.referenceId != null
    }
  },

  methods: {
    async submit() {
      this.submitting = true
      this.error = null
      try {
        const response = await axios.post("/api/reference/validate", { reference_code: this.referenceCode })
        const data = response.data
        this.referenceId = data.reference.id
        this.referenceCode = response.data.reference.code
        this.view = "complete"
        this.setFormData("referenceId", this.referenceId)
        this.setFormData("referenceCode", this.referenceCode)
        this.data.model = this.referenceCode
      }
      catch (error) {
        this.error = error.response.data.message
        this.submitting = false
      }
      finally {
        this.submitting = false
      }

      try {
        const response = await axios.post("/api/reference/personalia", { reference_code: this.referenceCode })
        const data = response.data
        this.personalia = data
      }
      catch (error) {
        // this is ok, id check can be done manually
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.disabled {
  cursor: not-allowed;
  color: gray;
  pointer-events: none;
}
</style>
